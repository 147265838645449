.settings {
    z-index: 2;
    background-color: var(--light-gray-3);
    box-shadow: 1px 0 0 rgba(149, 161, 198, 0.25);
    margin-top: 1px;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 16px 24px;
    display: flex;
    gap: 0;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.options {
    max-height: 0;
    transition: all 200ms ease-in-out;
}

.options-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
}

.options-title {
    display: inline-flex;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    transition: all 150ms ease-in-out;

    svg {
        margin-right: 12px;
    }
}

.options-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    margin: 0;
    cursor: pointer;

    .spacer{
        flex-grow: 1;
        margin-left: auto;
        margin-right: auto;
        height: 0;
    }

    > .switch + .options-controls {
        margin-left: 24px;
    }
}

.options-wrapper {
    overflow: hidden;
    margin-bottom: 8px;
    transition: opacity 150ms ease-in-out;

    &[data-disabled="true"] {
        opacity: .4;
        cursor: default;
        pointer-events: none;
    }

    h3 {
        margin: 0;
    }
}

.options-wrapper.is-active .options-header .options-title,
.options-header:hover .options-title {
    color: var(--blue);
}

.options-wrapper.is-active .options {
    max-height: 3000px;
}

.option {
    --hoverColor: var(--blue);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px 24px;
    width: 100%;
    background: transparent;
    cursor: pointer;
    font-size: 12px;
    line-height: 24px;
    color: var(--dark-gray);
    text-transform: capitalize;

    &:hover:not([data-disabled="true"]) .option-checkmark {
        border: 2px solid gray;

        &:before {
            transform: translate(-50%, -50%) scale(1);
            background: gray;
        }
    }

    &[data-disabled="true"] {
        opacity: 0.5;
    }

    &[data-active="true"] .option-checkmark {
        border: 2px solid var(--hoverColor);

        &:before {
            transform: translate(-50%, -50%) scale(1);
            background: var(--blue);
        }
    }
}

.option-checkmark {
    position: relative;
    width: 16px;
    height: 16px;
    border: 2px solid var(--lilac);
    border-radius: 50%;

    &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: var(--blue);
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: all 150ms ease-in-out;
    }
}
