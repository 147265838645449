@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/../src/fonts/Inter-Regular.woff2') format('woff2'),
    url('/../src/fonts/Inter-Regular.woff') format('woff');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/../src/fonts/Inter-Medium.woff2') format('woff2'),
    url('/../src/fonts/Inter-Medium.woff') format('woff');
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/../src/fonts/Inter-Bold.woff2') format('woff2'),
    url('/../src/fonts/Inter-Bold.woff') format('woff');
}

@font-face {
    font-family: Brandon;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/../src/fonts/Brandon-Medium.woff2') format('woff2'),
    url('/../src/fonts/Brandon-Medium.woff') format('woff');
}

@font-face {
    font-family: Brandon;
    font-weight: 700;
    font-display: swap;
    src: url('/../src/fonts/Brandon-Bold.woff2') format('woff2'),
    url('/../src/fonts/Brandon-Bold.woff') format('woff');
}
