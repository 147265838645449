:root {
    --blue: #009CDE;
    --light-blue: #E5F5FC;
    --white: #fff;
    --black: #000;
    --purple: #38234E;
    --pink: #FEF4F6;
    --red: #EF0025;
    --light-gray: #95A1C6;
    --dark-gray: #28314D;
    --light-gray-1: #E1E1E1;
    --light-gray-2: #E2E2E2;
    --light-gray-3: #F9FAFC;
    --light-gray-4: #E8ECF4;
    --light-gray-5: #F7F8FA;
    --aquamarine: #2E6780;
    --lilac: #95A1C6;
    --fontBrandon: Brandon, system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
}

html,
body {
    font-family: Inter,system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    line-height: 1.15; /* 1 */
    font-weight: 500;
    -webkit-text-size-adjust: 100%; /* 2 */
    -moz-tab-size: 4; /* 3 */
    tab-size: 4; /* 3 */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.debug-code {
    position: fixed;
    font-size: 10px;
    font-weight: bold;
    right: 0;
    top: 50%;
    background: rgb(0 0 0 / 60%);
    color: white;
    padding: 3px;
    border-radius: 3px;
}
