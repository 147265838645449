.signin-page {
  display: grid;
  grid-template-columns: 4fr minmax(350px, 3fr);
}

.signin-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F2FAFD;
  z-index: 2;
}

.signin-form {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    padding-left: 12px;
    font-family: var(--fontBrandon);
    font-weight: bold;
    font-size: 32px;
    line-height: 1.5;
    margin-bottom: 40px;
  }

  label {
    margin-bottom: 24px;

    div {
      padding-left: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
      color: #2E6780;
      margin-bottom: 8px;
    }
  }

  input:not([type='submit']) {
    display: block;
    background: var(--white);
    border: 2px solid rgba(0, 156, 222, 0.2);
    border-radius: 30px;
    line-height: 48px;
    width: 100%;
    padding: 0 20px;
  }

  input[type='submit'] {
    font-family: var(--fontBrandon);
    font-size: 16px;
    font-weight: 600;
    color: white;
    line-height: 48px;
    padding: 0 24px;
    text-transform: uppercase;
    border: none;
    border-radius: 30px;
    background-color: var(--blue);
    cursor: pointer;

    &[disabled] {
      opacity: .6;
    }
  }
}

.signin-error {
  position: absolute;
  top: 102%;
  padding: 10px 10px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border-radius: 10px;
}

.signin-submit-wrap {
  display: flex;
  justify-content: flex-end;
}

.signin-bg {
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .signin-logo {
    position: relative;
    padding: 60px 0 0 40px;
    z-index: 2;
  }
}
