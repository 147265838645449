body.scroll-lock {
    overflow: hidden;
    scrollbar-gutter: stable;
}

.header-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
}

.logo-image {
    width: 40px;
    height: 32px;
}

.logo-title {
    width: 80px;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.navbar-p {
    font-family: var(--fontBrandon);
    font-size: 16px;
}

.navbar-help {
    margin-right: 27px;
    width: 24px;
    height: 24px;
    opacity: 0.5; /* temp disabled */
}

.navbar-avatar {
    margin-right: 33px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.controls {
    position: sticky;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-column-start: 2;
    margin-bottom: 32px;
    max-height: 48px;
    padding-left: 48px;
}

.controls-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 12px 24px;
    border-radius: 30px;
    gap: 10px;
    height: 48px;
    cursor: pointer;

    &.medium {
        padding: 12px 24px 12px 12px;
    }

    &.short {
        padding: 12px 24px 12px 0;
        flex-grow: 0;
        justify-content: flex-start;
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
    }

    &.request-button {
        margin-top: 32px;

        p {
            white-space: nowrap;
        }
    }

    &.purple {
        background-color: var(--purple);
        color: var(--white);
    }

    &.red {
        color: var(--red);

        .icon-wrap {
            background-color: var(--pink);
        }
    }

    &.blue {
        color: var(--black);

        .icon-wrap {
            background-color: var(--light-blue);
        }
    }

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }

    svg.icon-request {
        width: 16px;
        height: 16px;
    }
    
    svg.icon-undo {
        width: 12px;
        height: 12px;
    }
    
    svg.icon-randomize {
        width: 24px;
        height: 24px;
    }
    
    svg.icon-new-char {
        width: 16px;
        height: 16px;
    }
}

.icon-wrap {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabs {
    display: flex;
    min-width: 400px;
    align-items: center;
    gap: 40px;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.tab {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;

    &:not(.active) {
        cursor: pointer;
    }

    &.disabled:not(.active) {
        opacity: 0.5;
    }

    h3 {
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        color: var(--black);
    }

    &.active{
        h3 {
            font-weight: 700;
        }
        .tab-icon {
            background-color: var(--light-blue);

            svg {
                filter: brightness(0) saturate(100%) invert(41%) sepia(11%) saturate(7499%) hue-rotate(168deg) brightness(105%) contrast(103%);
                fill: var(--blue) !important;
                color: var(--blue) !important;
            }
        }
    }
}

.tab-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--blue);
}

.tab-icon{
    svg.icon-character, svg.icon-objects, svg.icon-scenes, svg.icon-return {
        width: 24px;
        height: 24px;
    }
}

.export-label {
    font-family: var(--fontBrandon);
    font-weight: 420;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media all and (max-width: 960px) {
    .export-label {
        display: none;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 16px;

    & input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
}



.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-gray-4);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 8px;

  &:before {
    border-radius: 50%;
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -4px;
    bottom: -1px;
    background-color: var(--white);
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0 12px 5px rgba(27, 51, 105, 0.01), 0 7px 4px rgba(27, 51, 105, 0.05), 0 3px 3px rgba(27, 51, 105, 0.09), 0 1px 2px rgba(27, 51, 105, 0.1), 0 0 0 rgba(27, 51, 105, 0.1);
  }
}

input:checked + .slider {
  background-color: var(--blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

svg[data-detailed="true"] {
    transform: scale(1.5, 1.5);
}



.modal-area {
    z-index: 3;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px 24px 24px 24px;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.modal-window {
    background-color: var(--white);
    position: relative;
    box-shadow: 0 253px 101px rgba(27, 51, 105, 0.01), 0 143px 86px rgba(27, 51, 105, 0.05), 0 63px 63px rgba(27, 51, 105, 0.09), 0 16px 35px rgba(27, 51, 105, 0.1), 0 0 0 rgba(27, 51, 105, 0.1);
    border-radius: 40px;
    padding: 48px;
}

.modal-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
    margin-bottom: 32px;
}

.modal-close {
    position: absolute;
    top: 28px;
    right: 31px;
    height: 16px;
    cursor: pointer;
}

.modal-divider {
    opacity: 0.1;
    border-top: 2px solid #2688BC;
    height: 0;
    margin: 32px 0;
}

.modal-navigation {
    display: flex;
    justify-content: flex-end;
}

.button-blue {
    background-color: var(--blue);
    color: var(--white);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    padding: 12px 24px;
    font-family: var(--fontBrandon);
    text-transform: uppercase;
    height: 48px;
    border-radius: 30px;
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.create-option-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 16px;
}

.create-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 108px;
}

.create-options-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 12px;
}

.create-attribute {
    display: flex;
    gap: 10px;
    width: 106px;
    height: 106px;
    background-color: var(--light-gray-5);
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    input {
      display: none;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.checked {
        background: rgba(0, 156, 222, 0.1);
        cursor: default;

        p {
            font-weight: 700;
        }
    }

    &.thin {
      height: 40px;
    }
}

.attribute-label-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
}
