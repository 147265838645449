.header {
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 16px;
    gap: 12px;
}

.header-wrap {
    box-shadow: 0 1px 0 rgba(149, 161, 198, 0.25);
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 3;
    background-color: var(--white);
}

.layout {
    padding-top: 80px;
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 300px auto;

    &.symmetrical {
        grid-template-columns: 300px auto 300px;
    }

    .settings:nth-child(3){
        grid-column-start: 3;
    }
}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

/*.u-wrapper {*/
/*    border: 0;*/
/*    clip: rect(0 0 0 0);*/
/*    clip-path: inset(50%);*/
/*    height: 1px;*/
/*    margin: -1px;*/
/*    overflow: hidden;*/
/*    padding: 0;*/
/*    position: absolute;*/
/*    width: 1px;*/
/*    white-space: nowrap;*/
/*}*/

.pic-wrap {
    position: sticky;
    top: 80px;
    width: 100%;
    height: calc(100vh - 81px);
    overflow: hidden;
}

.pic {
    height: calc(100vh - 81px - 84px);
    transform-origin: top;
    transition: opacity 150ms ease-in-out;
    z-index: 1;

    &:not(.centered) .pic-svg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &.centered{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10%;
    }
}
